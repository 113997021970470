@use 'theme';
@use 'breakpoints';
@use 'typography';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: unset;
  background: right bottom no-repeat var(--backgroundUrl);
  background-color: theme.get('backgroundColor');
  @include breakpoints.up('largeMedium') {
    align-items: flex-start;
    background-size: contain;
  }
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: theme.spacing(5);
  max-width: 567px;
  z-index: 2;

  @include breakpoints.up('largeMedium') {
    align-items: flex-start;
    text-align: start;
  }

  @include breakpoints.up('large') {
    max-width: 700px;
  }
}

.header {
  margin-bottom: theme.spacing(2);

  @include breakpoints.up('large') {
    @include typography.get('huge');
  }
}

.highlight {
  color: theme.get('activeColor');
}

.actions {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: theme.spacing(3);

  @include breakpoints.up('small') {
    flex-direction: row;
  }
}

.phoneWrapper {
  display: inline-flex;
  white-space: nowrap;
  gap: theme.spacing(1);
}

.phone {
  color: theme.get('activeColor');
}

.image {
  margin-bottom: theme.spacing(-11.25);
  scale: 69%;

  @include breakpoints.up('small') {
    margin-bottom: theme.spacing(-12.25);
  }

  @include breakpoints.up('largeMedium') {
    margin-bottom: theme.spacing(0);
    scale: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
}